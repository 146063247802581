export default [
  {
    key: "titleEn",
    label: "field.titleEn",
    rules: "required|max:200",
    type: "text",
  },
  {
    key: "titleKm",
    label: "field.titleKm",
    rules: "required|max:200",
    type: "text",
  },
  {
    key: "weight",
    label: "field.weight",
    rules: "required|integer",
    type: "text",
  },
  {
    key: "sequenceOrder",
    label: "field.sequenceOrder",
    rules: "integer",
    type: "text",
  },
  {
    key: "descriptionEn",
    label: "field.descriptionEn",
    rules: "max:800",
    type: "textarea",
  },
  {
    key: "descriptionKm",
    label: "field.descriptionKm",
    rules: "max:800",
    type: "textarea",
  },
  {
    key: "isEnable",
    label: "field.active",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
];
